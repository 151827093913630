.App {
  text-align: center;
}

.button:hover {
  background: #9b3afc;
  border-radius: 4px;
}

.button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 20px 24px;
  gap: 8px;

  width: 188px;
  height: auto;

  /* Purple/Purple */

  background: #6e10cc;
  border: rgba(0, 234, 255, 0);
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 19.2px;
  line-height: 16px;
  color: white;
}
