@media screen and (max-width: 1024px) {
  .container__invest_or_fundraising--invest {
    height: 950px;
  }
}

@media screen and (max-width: 768px) {
  .container__invest_or_fundraising--invest {
    height: 1300px;
  }

  .container__invest_or_fundraising--invest
    .invest_or_fundraising--container-cards
    .container-card-cloumn1 {
    display: table-row;
  }
  .container__invest_or_fundraising--invest
    .invest_or_fundraising--container-cards
    .container-card-cloumn2 {
    display: table-row;
  }
}

@media screen and (max-width: 425px) {
  .container__invest_or_fundraising--invest {
    padding: 10px;
    height: 1900px;
  }

  .container__invest_or_fundraising--invest
    .invest_or_fundraising--container-cards
    .container-card {
    width: 100%;
  }
  .container__invest_or_fundraising--invest .buttons_container .invest {
    width: 140px;
    height: 56px;
  }
  .container__invest_or_fundraising--invest .buttons_container .fundraising {
    width: 140px;
    height: 56px;
  }

  .container__invest_or_fundraising--invest .title {
    font-size: 32px;
    line-height: 31px;
  }

  .container__invest_or_fundraising--invest .subtitle {
    font-size: 18px;
    line-height: 19px;
  }

  .container__invest_or_fundraising--invest
    .invest_or_fundraising--container-cards,
  .container__invest_or_fundraising--invest
    .invest_or_fundraising--container-cards
    .container-card,
  .container__invest_or_fundraising--invest
    .invest_or_fundraising--container-cards
    .container-card
    .card
    .card-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .container__invest_or_fundraising--invest
    .invest_or_fundraising--container-cards
    .container-card
    .card
    .card-text-container
    .title,
  .container__invest_or_fundraising--invest
    .invest_or_fundraising--container-cards
    .container-card
    .card
    .card-text-container
    .subtitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .container__invest_or_fundraising--invest .container .button__start-now {
    width: 231px;
    height: auto;
  }
}
