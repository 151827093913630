@media screen and (max-width: 1440px) {
  .slide-message-container,
  .slide-message-container__slide2 {
    gap: 32px;
  }
  .slide-message-container .title,
  .slide-message-container__slide2 .title {
    font-size: 45px;
    line-height: 36px;
  }
  .slide-message-container .subtitle,
  .slide-message-container__slide2 .subtitle {
    font-size: 25px;
    line-height: 28px;
  }
}

@media screen and (max-width: 1024px) {
  .dot,
  .dot-selected {
    width: 12px;
    height: 12px;
  }
  .slide-message-container,
  .slide-message-container__slide2 {
    gap: 22px;
  }
  .slide-message-container .title,
  .slide-message-container__slide2 .title {
    font-size: 25px;
    line-height: 26px;
  }
  .slide-message-container .subtitle,
  .slide-message-container__slide2 .subtitle {
    font-size: 15px;
    line-height: 18px;
  }
  .button {
    width: 128px;
    height: 36px;
    padding: 10px 14px;
    font-size: 12px;
    line-height: 14px;
  }
}

@media screen and (max-width: 768px) {
  .slider {
    height: 350px;
  }
  .slider .slide-container {
    height: 350px;
    margin-top: 9px;
  }
  .slider .slide-container > img {
    object-fit: cover;
  }

  .dotGroup {
    top: 95%;
    left: 46.5%;
    transform: translateY(-95%);
  }

  .dot,
  .dot-selected {
    width: 8px;
    height: 8px;
  }
  .buttonBack,
  .buttonNext {
    width: 38px;
    height: 38px;
  }
  .buttonBack > img,
  .buttonNext > img {
    width: 15px;
    height: 15px;
  }
  .slide-container .slide-message-container .title {
    font-size: 25px;
    line-height: 27px;
  }
  .slide-container .slide-message-container .subtitle {
    font-size: 18px;
    line-height: 18px;
  }

  .slide-container .slide-message-container__slide2 .title {
    font-size: 25px;
    line-height: 27px;
  }
  .slide-container .slide-message-container__slide2 .subtitle {
    font-size: 18px;
    line-height: 18px;
  }
}

@media screen and (max-width: 425px) {
  .slider {
    height: 350px;
  }
  .slider .slide-container {
    height: 350px;
  }
  .slider .slide-container > img {
    object-fit: cover;
  }
  .dotGroup {
    top: 95%;
    left: 42.5%;
    transform: translateY(-95%);
  }
  .dot,
  .dot-selected {
    width: 8px;
    height: 8px;
  }
  .buttonBack,
  .buttonNext {
    width: 28px;
    height: 28px;
  }
  .buttonBack > img,
  .buttonNext > img {
    width: 11px;
    height: 11px;
  }

  .slide-message-container {
    top: 35%;
  }

  .slide-container .slide-message-container .title {
    font-size: 16px;
    line-height: 17px;
  }
  .slide-container .slide-message-container .subtitle {
    font-size: 12px;
    line-height: 12px;
  }

  .slide-container .slide-message-container__slide2 .title {
    font-size: 16px;
    line-height: 17px;
  }
  .slide-container .slide-message-container__slide2 .subtitle {
    font-size: 12px;
    line-height: 12px;
  }
  .slide-container .button {
    width: 90px;
    height: 16px;
    padding: 10px 14px;
    font-size: 12px;
    line-height: 14px;
  }
}
