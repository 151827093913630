 
.slogan-section-container {
  width: 100%;
  position: relative;
  text-align: center;
}

.slogan-section-container > img {
  width: 100%;
}

.slogan-section-message-container {
  position: absolute;
  height: 112px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.slogan-section-message-container .title {
/* H2 */

font-family: 'Roboto';
font-style: italic;
font-weight: 400;
font-size: 47.78px;
line-height: 56px;
/* or 117% */

text-align: center;
font-feature-settings: 'liga' off;

/* Greyscale/White */

color: #FFFFFF;
}

.slogan-section-message-container .title > span { 
  color: #0BD69C; 
}
