@media screen and (max-width: 768px) {
  .footer-container {
    display: none;
  }

  .footer-container-mobile {
    font-family: "Roboto";
    font-style: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 16px;
    background: #2b2b2b;
    color: #ffffff;
  }

  .footer-container-mobile .social-media-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 16px;
    margin-bottom: 30px;
  }

  .footer-container-mobile
    .links-container
    .links
    .links-column-container
    > span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 19.2px;
    line-height: 24px;
    /* identical to box height, or 125% */

    font-feature-settings: "liga" off;

    color: #ffffff;
  }

  .footer-container-mobile
    .links-container
    .links
    .links-column-container
    > ul {
    margin-top: 8px;
    padding: 0px;
    list-style-type: none;
  }

  .footer-container-mobile
    .links-container
    .links
    .links-column-container
    > ul
    > li {
    margin-top: 8px;
    /* Body Regular */

    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    font-feature-settings: "liga" off;

    color: #ffffff;
  }

  .footer-container-mobile .cvm-rules-message-container {
    justify-content: center;
    align-items: center;
    text-align: cneter;
  }

  .footer-container-mobile .cvm-rules-message-container > span {
    text-align: start;
    /* Body Small */

    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 13.33px;
    line-height: 16px;
    /* or 120% */

    font-feature-settings: "liga" off;

    color: #ffffff;
  }

  .footer-container-mobile .copyright-container > span {
    text-align: start;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 13.33px;
    line-height: 16px;
    /* or 120% */

    font-feature-settings: "liga" off;

    color: #ffffff;
  }
}

@media screen and (max-width: 1024px) {
  .footer-container {
    padding: 30px;
  }
  .footer-container .links-container .logo {
    width: 200px;
    margin-right: 18px;
  }
}
