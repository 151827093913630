.container__invest_or_fundraising--fundraising {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 950px;
  /* Purple/Purple */

  background: #0bd69c;

  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  padding-bottom: 72px;
}

.container__invest_or_fundraising--fundraising .buttons_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 72px;
  margin-bottom: 56px;
}

.container__invest_or_fundraising--fundraising .buttons_container .invest:hover {
  background: #9B3AFC;
}

.container__invest_or_fundraising--fundraising .buttons_container .invest {
  /* Auto layout */

  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 24px;
  gap: 8px;

  width: 170px;
  height: 56px;

  /* Green/Green */

  background: #6e10cc;
  border-radius: 4px;
  border: rgba(0, 234, 255, 0);

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 19.2px;
  line-height: 16px;
  /* identical to box height, or 83% */

  /* Greyscale/White */

  color: #ffffff;
}
 
.container__invest_or_fundraising--fundraising .buttons_container .fundraising {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 24px;
  gap: 8px;

  width: 160px;
  height: 56px;

  /* Green/Green */

  background: #0BD69C;
  border-radius: 4px;
  border: rgba(0, 234, 255, 0);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 19.2px;
  line-height: 16px;
  /* identical to box height, or 83% */

  /* Greyscale/White */

  color: #ffffff;
}

.container__invest_or_fundraising--fundraising .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  margin-top: 10px;
}

.container__invest_or_fundraising--fundraising .title {
  /* H1 */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 57.33px;
  line-height: 56px;
  /* identical to box height, or 98% */

  text-align: center;
  font-feature-settings: "liga" off;

  /* Greyscale/White */

  color: #ffffff;
}

.container__invest_or_fundraising--fundraising .subtitle {
  /* S3 */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 23.04px;
  line-height: 32px;
  /* or 139% */

  text-align: center;
  font-feature-settings: "liga" off;

  /* Greyscale/White */

  color: #ffffff;
  margin-top: 18px;
  margin-bottom: 104px;
}

.container__invest_or_fundraising--fundraising .invest_or_fundraising--container-cards {
  display: table;
  table-layout: fixed; 
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-spacing: 10px;
  margin-top: -50px;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
}

.container__invest_or_fundraising--fundraising
  .invest_or_fundraising--container-cards
  .container-card-cloumn1 {
  display: table-cell;
  flex-direction: row;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.container__invest_or_fundraising--fundraising
  .invest_or_fundraising--container-cards
  .container-card-cloumn2 {
    display: table-cell;
    flex-direction: row;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.container__invest_or_fundraising--fundraising
  .invest_or_fundraising--container-cards
  .container-card {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 376px;
  height: 344px;
}

.container__invest_or_fundraising--fundraising
  .invest_or_fundraising--container-cards
  .container-card
  .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.container__invest_or_fundraising--fundraising
  .invest_or_fundraising--container-cards
  .container-card
  .card
  > img {
  width: 120px;
  height: 120px;
}

.container__invest_or_fundraising--fundraising
  .invest_or_fundraising--container-cards
  .container-card
  .card
  .card-text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
}

.container__invest_or_fundraising--fundraising
  .invest_or_fundraising--container-cards
  .container-card
  .card
  .title {
  /* H4 */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 33.18px;
  line-height: 40px;
  /* identical to box height, or 121% */

  font-feature-settings: "liga" off;

  /* Greyscale/White */

  color: #ffffff;
  text-align: start;
}

.container__invest_or_fundraising--fundraising
  .invest_or_fundraising--container-cards
  .container-card
  .card
  .subtitle {
  /* Body Large */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 19.2px;
  line-height: 24px;
  /* or 125% */

  font-feature-settings: "liga" off;

  /* Greyscale/White */

  color: #ffffff;
  text-align: start;
}

.container__invest_or_fundraising--fundraising .container .button__start-now:hover {
  background: #9B3AFC;
}

.container__invest_or_fundraising--fundraising .container .button__start-now {
  /* Auto layout */

  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 20px 24px;
  gap: 8px;

  width: 581px;
  height: auto; 

  /* Green/Green */

  background: #6e10cc;
  border-radius: 4px;
  border: rgba(0, 234, 255, 0);

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 19.2px;
  line-height: 16px;
  /* identical to box height, or 83% */

  /* Greyscale/White */

  color: #ffffff;
  margin-top: 8px;
}
