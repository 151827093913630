@media screen and (max-width: 768px) {
  .container__oportunities .container_cards > span {
    font-size: 47px;
    line-height: 36px;
  }

  .container__oportunities .background {
    width: 100%;
    height: 100%;
    margin-top: 25%;
  }
  .container__oportunities .container_cards {
    top: 220px;
    height: 550px!important;
  }
  .container__oportunities .container_cards .container_card {
    top: 100px;
  }
}

@media screen and (max-width: 425px) {
  .container__oportunities .background {
    width: 100%;
    height: 100%;
    margin-top: 115%;
  }
  .container__oportunities .title_container .subtitle {
    margin: 15px;
    font-size: 30px;
    line-height: 33px;
  }
  .container__oportunities .title_container .subtitle1 {
    margin: 15px;
  }
  .container__oportunities .container_cards {
    margin: auto;
    text-align: center;
    height: 550px!important;
  }
  .container__oportunities .container_cards > span {
    font-size: 37px;
    line-height: 36px;
  }

  .container__oportunities .container_cards .container_card {
    width: 294px !important;
    max-width: 294px !important;
    min-width: 294px !important;  
    top: 150px;
  }

  .container__oportunities .container_cards .container_card .banner_card {
    top: -10px;
  }
  .container__oportunities .container_cards .container_card .ubits_logo {
    width: 150px;
    height: 40px;
    padding: 8px 8px;
    top: 135px;
  }
  .container__oportunities
    .container_cards
    .container_card
    .content_card
    .title_card {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 19px;
  }
  .container__oportunities
    .container_cards
    .container_card
    .content_card
    .description_card {
    font-weight: 400;
    font-size: 12px;
  }

  .container__oportunities
    .container_cards
    .container_card
    .content_card
    .progress_container_card
    .progress_title_percent_card
    .progress_title_card,
  .container__oportunities
    .container_cards
    .container_card
    .content_card
    .progress_container_card
    .progress_title_percent_card
    .progress_percentage_card {
    font-size: 14px;
  }

  .container__oportunities
    .container_cards
    .container_card
    .content_card
    .progress_container_card
    .progress_bar_card {
    height: 15px;
  }

  .container__oportunities
    .container_cards
    .container_card
    .want_invest_button_card {
    font-size: 14px;
    height: 36px;
  }

  .container__oportunities .more_opportunities_button {
    width: 175px;
    height: 16px;
    top: 85%;
  }
  
  .container__oportunities .comming_soon {
    font-size: 29.2px;
    line-height: 16px;
    top: 52%;
  }
}
