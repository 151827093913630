.advantage-section1-container {
  display: table;
  table-layout: fixed;
  border-spacing: 50px;
  width: 100%;
  height: 100%;
}

.advantage-section1-container > img {
  width: 100%;
  display: table-cell;
  padding-left: 5%;
}

.advantage-section1-container .message-container {
  display: table-cell;
  vertical-align: middle;
  text-align: start;
  padding-left: 10%;
}

.advantage-section1-container .message-container .title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 47.78px;
  line-height: 56px;
  /* or 117% */

  font-feature-settings: "liga" off;

  /* Greyscale/Texts */

  color: #2b2b2b;
}

.advantage-section1-container .message-container .subtitle {
  width: 80%;
  height: 25%;
  margin-top: 40px;
  margin-bottom: 40px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 19.2px;
  line-height: 24px;
  /* or 125% */

  /* Greyscale/Texts */

  color: #2b2b2b;
}
