.container__advantages {
  position: relative;
  text-align: center;
  margin-top: -10px;
  overflow: hidden;
  height: 680px;
}

.container__advantages > img {
  width: 100%;
  margin-top: -20%;
}

.container__advantages .title {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 64px;

  /* H1 */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 57.33px;
  line-height: 56px;
  /* or 98% */

  text-align: center;
  font-feature-settings: "liga" off;

  /* Greyscale/Texts */

  color: #2b2b2b;
}

.container__advantages .title .period {
  /* Green/Green */
  color: #0bd69c;
}

.container__advantages .container-cards {
  position: absolute;
  display: table;
  table-layout: fixed;
  border-spacing: 100px;
  top: 50;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.container__advantages .container-card {
  position: relative;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-radius: 12px;
}

.container__advantages .container-card:hover {
  cursor: pointer;
}

.container__advantages .container-card .card-security,
.container__advantages .container-card .card-accessibility,
.container__advantages .container-card .card-facility {
  width: 288px;
  height: 250px;
  display: flex;
  background: #f5f5f5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 16px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
}

.container__advantages .container-card .card-security-hide,
.container__advantages .container-card .card-facility-hide,
.container__advantages .container-card .card-accessibility-hide {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 288px;
  height: 250px;
  padding: 5px;
  gap: 16px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  background: #442a7a;
}

/* .container__advantages .card-security:hover,
.container__advantages .card-facility:hover,
.container__advantages .card-accessibility:hover { 
  display: none;
} */

/* .container__advantages .card-security:hover + .card-security-hide,
.container__advantages .card-facility:hover + .card-facility-hide,
.container__advantages .card-accessibility:hover + .card-accessibility-hide {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background: #442a7a;
} */

.container__advantages .card-security > span,
.container__advantages .card-facility > span,
.container__advantages .card-accessibility > span {
  /* H3 */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 39.81px;
  line-height: 48px;
  /* identical to box height, or 121% */

  text-align: center;
  font-feature-settings: "liga" off;

  /* Greyscale/Texts */

  color: #2b2b2b;
}

.container__advantages .card-security-hide .title,
.container__advantages .card-facility-hide .title,
.container__advantages .card-accessibility-hide .title {
  height: 64px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 27.65px;
  line-height: 32px;
  /* or 116% */

  text-align: center;

  /* Greyscale/White */

  color: #ffffff;
}

.container__advantages .card-security-hide .subtitle,
.container__advantages .card-facility-hide .subtitle,
.container__advantages .card-accessibility-hide .subtitle {
  /* Body Small */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13.33px;
  line-height: 16px;
  margin-top: 85px;
  /* or 120% */

  text-align: center;
  font-feature-settings: "liga" off;

  /* Greyscale/White */

  color: #ffffff;
}

.container__advantages .container-card .card-accessibility-hide .title {
  height: 64px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 25.65px;
  line-height: 30px;
  /* or 116% */

  text-align: center;

  /* Greyscale/White */

  color: #ffffff;
}
