.button-scroll-top-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  position: fixed;
  z-index: 999;
  width: 64px;
  height: 64px;
  right: 72px;
  bottom: 72px;

  /* Green/Green */

  background: #0bd69c;
  /* Hover Button */

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

@media screen and (max-width: 425px) { 
  .button-scroll-top-container {
    display: none;
  }
}
