@media screen and (max-width: 1024px) {

  .container__advantages .container-cards {
    border-spacing: 50px;
  }
  .container__advantages .container-card .card-security,
  .container__advantages .container-card .card-accessibility,
  .container__advantages .container-card .card-facility {
    width: 230px;
    height: 230px;
  }
  .container__advantages .container-card .card-security-hide,
  .container__advantages .container-card .card-accessibility-hide,
  .container__advantages .container-card .card-facility-hide {
    width: 230px;
    height: 230px;
  }
}

@media screen and (max-width: 768px) {
  .container__advantages {
    height: 1300px;
  }
  .container__advantages > img {
    width: 100%;
    margin-top: 0%;
  }
  .container__advantages .container-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 90px;
    gap: 40px;
  }

  .container__advantages .title {
    font-size: 37.33px;
    line-height: 36px;
  }

  .container__advantages .container-card .card-security,
  .container__advantages .container-card .card-accessibility,
  .container__advantages .container-card .card-facility {
    width: 230px;
    height: 230px;
  }
  .container__advantages .container-card .card-security-hide,
  .container__advantages .container-card .card-accessibility-hide,
  .container__advantages .container-card .card-facility-hide {
    width: 230px;
    height: 230px;
  }

.container__advantages .container-card .card-accessibility-hide .title {
  font-size: 20.65px;
  line-height: 25px;
}
}
