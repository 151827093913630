@media screen and (max-width: 768px) {
  .advantage-section3-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: start;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .advantage-section3-container > img {
    width: 80%;
    display: table-cell;
    text-align: start;
    padding-left: 0;
  }

  .advantage-section3-container .message-container {
    width: 80%;
    vertical-align: middle;
    text-align: start;
    padding-left: 0;
  }

  .advantage-section3-container .message-container .subtitle {
    margin-bottom: 50px;
  }
}
