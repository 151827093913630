@media screen and (max-width: 1024px) {
  .header-container .logo {
    width: 100px;
  }
}

@media screen and (max-width: 1024px) {
  .header-container {
    height: 46px;
  }
}

@media screen and (max-width: 768px) {
  .header-container .logo {
    width: 90px;
  }

  .header-container .buttons-container .button-signup {
    height: 20px;
  }
}

@media screen and (max-width: 768px) {
  .header-container {
    height: 46px;
    padding: 10px 18px;
  }
  .header-container .buttons-container .buttons {
    height: 30px;
    font-size: 12px;
  }
  .header-container .buttons-container .button-login {
    height: 30px;
    font-size: 12px;
  }
  .header-container .buttons-container .button-signup {
    height: 10px;
    font-size: 11px;
    line-height: 12px;
  }
  .header-container .buttons-container .languages-container .dropdown {
    top: 50px;
    right: 20px;
  }
}

@media screen and (max-width: 425px) {
  .header-container .logo {
    width: 90px;
  }
  .header-container .buttons-container .buttons {
    display: none;
  }
  .header-container .buttons-container {
    display: none;
  }
  .header-container .dropdown-mobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 60px;
    padding-right: 5px;
  }
  .header-container .dropdown-mobile .dropdown:hover {
    opacity: 1;
    visibility: visible;
  }
  .header-container .dropdown-mobile .menu:hover {
    color: #9b3afc;
  }
  .header-container .dropdown-mobile .menu {
    width: 30px;
  }
  .header-container .dropdown-mobile .menu:hover + .dropdown {
    opacity: 1;
    visibility: visible;
  }

  .header-container .dropdown-mobile .dropdown .button-login {
    color: #0bd69c;
    border-radius: 4px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    padding: 5px;
    border: 1px solid #0bd69c;
    border-radius: 4px;
    text-decoration: none;
  }

  .header-container .dropdown-mobile .dropdown .button-signup {
    color: #ffff;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    padding: 5px;
    background: #6e10cc;
    border: rgba(0, 234, 255, 0);
    border-radius: 4px;
    text-decoration: none;
  }

  .header-container .dropdown-mobile .dropdown > a {
    text-decoration: none;
    color: #6e10cc;
    cursor: auto;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

  .header-container .dropdown-mobile .dropdown {
    /* Auto layout */
    position: absolute;
    top: 50px;
    right: 20px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease;
    /* display: none; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    background: #ffff;
    border-radius: 6px;
    gap: 15px;
  }

  .header-container
    .dropdown-mobile
    .languages-container
    .language-selected
    .flag-language
    .sigla {
    display: none;
  }

  .header-container
    .dropdown-mobile
    .languages-container
    .language-selected
    .arrow-down {
    display: none;
  }
  .header-container
    .dropdown-mobile
    .languages-container
    .language-selected
    .arrow-up {
    display: none;
  }
  .header-container .dropdown-mobile .languages-container .dropdown {
    /* Auto layout */
    position: absolute;
    top: 60px;
    right: 100px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease;
    /* display: none; */
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    padding-top: 0px;
    padding-bottom: 0px;
    background: #ffffff;
    border-radius: 6px;
  }

  .header-container .dropdown-mobile .languages-container .dropdown:hover {
    opacity: 1;
    visibility: visible;
  }

  .header-container
    .dropdown-mobile
    .languages-container
    .dropdown
    .dropdown-item
    .sigla {
    display: none;
  }

  .header-container .dropdown-mobile .language-selected:hover + .dropdown {
    opacity: 1;
    visibility: visible;
    transition: opacity 1.5s ease;
  }
}


