.container {
  position: relative;
  margin: auto;
}

.carousel {
  padding: 0 !important;
  margin: 0 !important;
  background: rgba(0, 0, 0, 0) ;
}

.buttonBack {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 234, 255, 0);
  border: rgba(0, 234, 255, 0);
  width: 48px;
  height: 48px;
  left: 10px;
}

.buttonNext {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 234, 255, 0);
  border: rgba(0, 234, 255, 0);
  width: 48px;
  height: 48px;
  right: 10px;
}

.dotGroup {
  position: absolute;
  top: 90%;
  left: 47.5%;
  transform: translateY(-90%);
}

.dot {
  border: rgba(0, 234, 255, 0);
  margin-right: 8px;
  width: 16px;
  height: 16px;
  background: #ffffff;
  opacity: 0.5;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.dot-selected {
  border: rgba(0, 234, 255, 0);
  margin-right: 8px;
  width: 16px;
  height: 16px;
  background: #0bd69c;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.buttonNext:hover,
.buttonBack:hover {
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
}
 
.slide-container {
  position: relative;
  text-align: center;
}

.slide-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
  padding: 0px;
  gap: 32px;

  position: absolute;

  width: 35%;
  height: 30%;
  left: 16%;
  top: 45%;
}


.slide-message-container__slide2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
  padding: 0px;
  gap: 32px;

  position: absolute;

  width: 35%;
  height: 30%;
  right: 6.5%;
  top: 35%;
}

.slide-message-container .title, .slide-message-container__slide2 .title {
  /* H1 */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 57.33px;
  line-height: 56px;
  /* or 98% */

  font-feature-settings: "liga" off;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.slide-message-container  .subtitle, .slide-message-container__slide2  .subtitle {
  /* S1 */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 33.18px;
  line-height: 40px;
  /* or 121% */

  font-feature-settings: "liga" off;

  /* Greyscale/White */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.slide-container .slide-container-degrade {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px; 
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(141, 141, 141, 0.5) 45.24%, rgba(0, 0, 0, 0.6) 66.3%);
  background-blend-mode: multiply;
}

.slide-container .slide-container-degrade__slide2 {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px; 
  background: linear-gradient(270deg, #2B2B2B 0%, rgba(141, 141, 141, 0.5) 45.24%, rgba(255, 255, 255, 0) 66.3%);
  background-blend-mode: multiply;
}

