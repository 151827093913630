.container__oportunities {
  position: relative;
  text-align: center;
  overflow: hidden;
  height: 980px;
}

.container__oportunities .background {
  width: 100%;
  height: 100%;
  margin-top: 5%;
  position: absolute;
  left: 0;
}

.container__oportunities .title_container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  top: 64px;
}

.container__oportunities .title_container .title {
  /* H5 */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 27.65px;
  line-height: 32px;
  /* or 116% */

  text-align: center;
  font-feature-settings: "liga" off;

  /* Greyscale/Texts */

  color: #2b2b2b;
}

.container__oportunities .title_container .title_underscore_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;
  margin-top: 4px;
  margin-bottom: 32px;
}

.container__oportunities .title_container .underscore {
  /* Rectangle 1 */

  width: 218px;
  height: 8px;

  /* Purple/Purple */

  background: #6e10cc;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.container__oportunities .title_container .period {
  /* Rectangle 2 */

  width: 8px;
  height: 8px;

  /* Green/Green */

  background: #0bd69c;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.container__oportunities .title_container .subtitle {
  /* H1 */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 57.33px;
  line-height: 56px;
  /* identical to box height, or 98% */

  text-align: center;
  font-feature-settings: "liga" off;

  /* Greyscale/Texts */

  color: #2b2b2b;
}

.container__oportunities .title_container .subtitle1 {
  margin-top: 20px;

  /* S3 */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 23.04px;
  line-height: 32px;
  /* identical to box height, or 139% */

  text-align: center;
  font-feature-settings: "liga" off;

  /* Greyscale/Texts */

  color: #2b2b2b;
}

.container__oportunities .container_cards > span {
  /* H1 */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 67.33px;
  line-height: 56px;
  /* identical to box height, or 98% */

  text-align: center;
  font-feature-settings: "liga" off;

  /* Greyscale/Texts */

  color: #2b2b2b;
}

.container__oportunities .container_cards {
  position: relative;
  overflow-x: scroll;
  top: 280px;
  background: #ffffff00;
}

.container__oportunities .container_cards table {
  display: inline;
  justify-content: center;
  align-items: center;
  border-spacing: 10px;
  max-height: 403px !important;
  background: #ffffff00;
  border-radius: 12px;
}

.container__oportunities .container_cards .container_card {
  /* Auto layout */
  max-width: 394px !important;
  min-width: 394px !important;
  position: relative;
  background: #ffffff;

  box-shadow: 0px 0px 3px 3px rgba(138, 138, 138, 0.3);
  border-radius: 12px;
}

.container__oportunities
  .container_cards
  .container_card
  .banner_card
  .private {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Greyscale/White */

  color: #ffffff;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 100px;
  height: 30px;
  right: 0;
  top: 60px;
  z-index: 4;

  /* Purple/Purple */

  background: #ee0303;
  border-radius: 20px 0px 0px 20px;
}

.container__oportunities
  .container_cards
  .container_card
  .banner_card
  .country {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 2px 2px 2px 0px; */
  gap: 8px;

  position: absolute;
  width: 129px;
  height: 36px;
  left: 0;
  top: 21px;
  z-index: 20;

  /* Purple/Purple */

  background: #6e10cc;
  border-radius: 0px 20px 0px 0px;
}

.container__oportunities .container_cards .container_card .banner_card .flag {
  /* Flag */

  width: 24px;
  height: 24px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.container__oportunities .container_cards .container_card .banner_card .tech {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Greyscale/White */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.container__oportunities
  .container_cards
  .container_card
  .banner_card
  .background-banner {
  position: absolute;
  width: 100%;
  height: 100%;
}

.container__oportunities .container_cards .container_card .ubits_logo {
  /* Logo Startup */
  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  z-index: 2;

  position: absolute;
  width: 150px;
  height: 40px;

  left: 0;
  right: 0;
  margin: 0 auto;

  top: 122px;
  z-index: 10;

  /* Greyscale/White */

  background: #ffffff;
  /* Hover Button */

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.container__oportunities .container_cards .container_card .banner_card {
  /* Auto layout */
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  /* top: -10px; */
  height: 180px;

  background: #2f322f;
  border-radius: 12px 12px 0px 0px;
}

.container__oportunities .container_cards .container_card .content_card {
  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 14px;
}

.container__oportunities
  .container_cards
  .container_card
  .content_card
  .title_card {
  /* H5 */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 800;
  font-size: 27.65px;
  line-height: 32px;
  /* identical to box height, or 116% */

  font-feature-settings: "liga" off;

  /* Greyscale/Texts */

  color: #2b2b2b;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin-top: 16px;
  margin-bottom: 8px;
  width: 100%;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}

.container__oportunities
  .container_cards
  .container_card
  .content_card
  .description_card {
  width: 100%;
  height: 80px;
  margin-bottom: 8px;
  align-items: flex-start;
  text-align: start;
}

.container__oportunities
  .container_cards
  .container_card
  .content_card
  .description_card
  > p {
  position: absolute;
  height: 55px;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: flex-start;
  display: -webkit-box;
  -webkit-line-clamp: 3; 
  -webkit-box-orient: vertical;
  padding-right: 10px;

  /* Body Small */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13.33px;
  line-height: 16px;
  /* or 120% */

  font-feature-settings: "liga" off;

  /* Greyscale/Texts */

  color: #2b2b2b;
}

.container__oportunities
  .container_cards
  .container_card
  .content_card
  .description_card
  .fade {
  position: relative;
  width: 100%;
  height: 50px;
  top: 32px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 70%);
  /* background: red; */
}

.container__oportunities
  .container_cards
  .container_card
  .content_card
  .progress_container_card {
  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* padding: 0px;
  gap: 8px; */
  margin-bottom: 24px;

  width: 100%;
  height: 56.5px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}

.container__oportunities
  .container_cards
  .container_card
  .content_card
  .progress_container_card
  .progress_title_percent_card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 24.25px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}

.container__oportunities
  .container_cards
  .container_card
  .content_card
  .progress_container_card
  .progress_title_percent_card
  .progress_title_card {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Greyscale/Texts */

  color: #2b2b2b;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.container__oportunities
  .container_cards
  .container_card
  .content_card
  .progress_container_card
  .progress_title_percent_card
  .progress_percentage_card {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Greyscale/Texts */

  color: #2b2b2b;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.container__oportunities
  .container_cards
  .container_card
  .content_card
  .progress_container_card
  .progress_bar_card {
  width: 100%;
  height: 24.25px;

  /* Greyscale/Grey 2 */

  background: #e6e6e6;
  border-radius: 16px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}

.container__oportunities
  .container_cards
  .container_card
  .content_card
  .progress_container_card
  .progress {
  width: 365px;
  height: 100%;
  position: relative;
  left: 0%;
  right: 35.57%;
  top: 0%;
  bottom: 0%;

  /* Green/Green */

  background: #0bd69c;
  border-radius: 16px;
}

.container__oportunities
  .container_cards
  .container_card
  .want_invest_button_card:hover {
  background: #6e10cc;
  color: #ffffff;
}

.container__oportunities
  .container_cards
  .container_card
  .want_invest_button_card {
  /* Body Large */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 19.2px;
  line-height: 24px;
  text-decoration: none;
  /* identical to box height, or 125% */

  font-feature-settings: "liga" off;

  /* Purple/Purple */

  color: #6e10cc;
  /* Button */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 0px; */
  /* margin-top: 24px; */

  width: 100%;
  height: 56px;

  /* Purple/Purple */

  border: 1px solid #6e10cc;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.container__oportunities .more_opportunities_button:hover {
  background: #9b3afc;
}

.container__oportunities .more_opportunities_button {
  /* Button */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 19.2px;
  line-height: 16px;
  /* identical to box height, or 83% */

  /* Greyscale/White */

  color: #ffffff;

  /* Auto layout */

  /* position: absolute; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 24px;
  left: 0;
  right: 0;
  margin: 0 auto;

  width: 375px;
  height: 26px;
  z-index: 1;

  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 85%;
  text-decoration: none;

  /* Purple/Purple */

  background: #6e10cc;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.container__oportunities .comming_soon {
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 52%;
  left: 0;
  right: 0;
  margin: 0 auto;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 59.2px;
  line-height: 16px;
}
