@media screen and (max-width: 768px) {
  .slogan-section-message-container .title {
    font-size: 27px;
    line-height: 36px;
  }
}
@media screen and (max-width: 425px) {
  .slogan-section-container > img {
    width: 100%;
    height: 120px;
  }
  .slogan-section-message-container .title {
    font-size: 20px;
    line-height: 25px;
  }
}
