.container__faq {
  position: relative;
  text-align: justify;
  overflow: hidden;
  max-height: 990px;
}

.container__faq > img {
  width: 100%;
  margin-top: -10%;
}

.container__faq .title_container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  top: 64px;
}

.container__faq .title_container .title {
  /* H1 */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 57.33px;
  line-height: 56px;
  /* identical to box height, or 98% */

  font-feature-settings: 'liga' off;

  /* Greyscale/Texts */

  color: #2b2b2b;
}

.container__faq .title_container .title .period {
  color: #0bd69c;
}

.container__faq .container-accordions {
  width: 770px;
  /* height: 100%; */
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 20px;
  top: 180px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.container__faq .container-accordions .accordion__closed {
  cursor: pointer;
  width: 738px;
  max-height: 80px;
  transition: max-height 0.2s ease;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  /* Greyscale/White */

  background: #ffffff;
  /* Greyscale/Grey 2 */

  border: 1px solid #e6e6e6;
  border-radius: 8px;
}

.container__faq .container-accordions .accordion__closed .title {
  /* H5 */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  text-align: start;
  line-height: 32px;
  /* identical to box height, or 116% */

  font-feature-settings: 'liga' off;

  /* Greyscale/Texts */

  color: #2b2b2b;
}

.container__faq .container-accordions .accordion__open {
  cursor: pointer;
  width: 738px;
  transition: max-height 1.5s ease;
  max-height: 328px;
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;

  /* Greyscale/White */

  background: #ffffff;
  /* Greyscale/Grey 2 */

  border: 1px solid #e6e6e6;
  border-radius: 8px;
}

.container__faq .container-accordions .accordion__open .accordion__header {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 738px;
  height: 80px;

  /* Greyscale/White */

  background: #ffffff;
  margin-bottom: 5px;
}

.container__faq
  .container-accordions
  .accordion__open
  .accordion__header
  .title {
  /* H5 */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
  /* identical to box height, or 116% */

  font-feature-settings: 'liga' off;

  /* Greyscale/Texts */

  color: #6e10cc;
}

.container__faq .container-accordions .accordion__open .content {
  overflow: scroll;
  text-align: justify;
  font-size: 12px;
}

.container__faq .container-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 91px;
  margin: auto;
  gap: 30px;
}

.container__faq .container-accordions .container-footer .title {
  /* H4 */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 33.18px;
  line-height: 40px;
  /* identical to box height, or 121% */

  font-feature-settings: 'liga' off;

  /* Greyscale/Texts */

  color: #2b2b2b;
}

.container__faq .container-accordions .container-footer .subtitle {
  /* Body Large */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 19.2px;
  line-height: 24px;
  /* or 125% */

  text-align: center;
  font-feature-settings: 'liga' off;

  /* Greyscale/Texts */

  color: #2b2b2b;
}

.container__faq .container-accordions .container-footer .button:hover {
  background: #9b3afc;
}

.container__faq .container-accordions .container-footer .button {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 20px 24px;
  gap: 8px;

  width: 90%;
  height: auto;

  /* Purple/Purple */

  background: #6e10cc;
  border-radius: 4px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 19.2px;
  line-height: 16px;
  text-decoration: none;
  /* identical to box height, or 83% */

  /* Greyscale/White */

  color: #ffffff;
}
