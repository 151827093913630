.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 72px;

  position: fixed;
  z-index: 999;
  height: 76px;
  left: 0px;
  right: 0px;
  top: 0px;

  background: rgba(255, 255, 255, 0.5);
  /* Navbar Default */

  box-shadow: 0px 4px 20px 5px rgba(138, 138, 138, 0.3);
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */
}

.header-container .dropdown-mobile {
  display: none;
}

.header-container .buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.header-container .buttons-container .buttons {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
  /* identical to box height, or 100% */

  /* Greyscale/White */

  color: #ffffff;
}

.header-container .buttons-container .buttons:hover {
  color: #2b2b2b;
}

.header-container .buttons-container .buttons {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
}

.header-container .buttons-container > a {
  border: rgba(0, 234, 255, 0);
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.header-container .buttons-container .button-login:hover {
  border: 1px solid #6e10cc;
  color: #6e10cc;
}

.header-container .buttons-container .button-login {
  /* Button */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;

  width: 72px;
  height: 44px;

  /* Greyscale/White */

  border: 1px solid #ffffff;
  border-radius: 4px;

  /* Body Regular */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  font-feature-settings: "liga" off;

  /* Greyscale/White */

  color: #ffffff;
  text-decoration: none;
}

.header-container .buttons-container .button-signup:hover {
  background: #9b3afc;
}

.header-container .buttons-container .button-signup {
  /* Auto layout */

  cursor: pointer; 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;   
  text-decoration: none; 

  width: 188px;
  height: 25px; 

  /* Purple/Purple */

  background: #6e10cc;
  border: rgba(0, 234, 255, 0);
  border-radius: 4px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  /* Greyscale/White */

  color: #ffffff;

}

html:not([data-scroll="0"]) .header-container .buttons-container .buttons {
  color: #2b2b2b;
}

html:not([data-scroll="0"])
  .header-container
  .buttons-container
  .buttons:hover {
  color: #6e10cc;
}

html:not([data-scroll="0"]) .header-container .buttons-container .button-login {
  border: 1px solid #6e10cc;
  color: #6e10cc;
}

html:not([data-scroll="0"])
  .header-container
  .buttons-container
  .button-login:hover {
  color: #6e10cc;
  background: rgba(110, 16, 204, 0.1);
  border: 1px solid #6e10cc;
}

.header-container .buttons-container .languages-container {
  /* Auto layout */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.header-container .buttons-container .language-selected {
  /* Auto layout */
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 8px;
  gap: 8px;
}

.header-container .buttons-container .language-selected .flag-language {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.header-container
  .buttons-container
  .languages-container 
  .arrow-down {
    display: block;
}

.header-container
  .buttons-container
  .languages-container 
  .arrow-up {
    display: none;
}

.header-container
  .buttons-container
  .languages-container:hover
  .arrow-down {
    display: none;
}

.header-container
  .buttons-container
  .languages-container:hover .arrow-up {
    display: block;
}

.header-container .buttons-container .languages-container .dropdown {
  /* Auto layout */
  position: absolute;
  top: 70px;
  right: 80px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease;
  /* display: none; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
  background: #ffffff;
  border-radius: 6px;
}
 

.header-container .buttons-container .languages-container .dropdown:hover {
  opacity: 1;
  visibility: visible;
}

.header-container
  .buttons-container
  .languages-container
  .language-selected:hover
  + .dropdown {
  opacity: 1;
  visibility: visible;
  transition: opacity 1.5s ease;
}

.header-container .buttons-container .languages-container .dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 8px;
  gap: 8px;

  /* Body Regular */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  font-feature-settings: "liga" off;

  color: #000000;
}

.header-container .buttons-container .languages-container .dropdown-item:hover {
  color: #6e10cc;
}
