.footer-container {
  background: #2b2b2b;
  padding-top: 64px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 100px;
}

.footer-container-mobile {
  display: none;
}

.footer-container .links-container .logo { 
  margin-right: 28px;
}
.footer-container .links-container > img {
  max-width: 100%;
}

.footer-container .links-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
}

.footer-container .links-container .links { 
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.footer-container .links-container .links .links-column-container { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
  padding: 0px;
  margin-right: 30px;
}

.footer-container .links-container .links .links-column-container > span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 19.2px;
  line-height: 24px;
  /* identical to box height, or 125% */

  font-feature-settings: "liga" off;

  color: #ffffff;
}

.footer-container .links-container .links .links-column-container > ul {
  margin-top: 8px;
  padding: 0px;
  list-style-type: none;
}

.footer-container .links-container .links .links-column-container > ul > li {
  margin-top: 8px;
  /* Body Regular */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  font-feature-settings: "liga" off;

  color: #ffffff;
}

.footer-container .cvm-rules-message-container {
  width: 100%;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  margin-top: 96px;
}

.footer-container .cvm-rules-message-container > span {
  text-align: start;
  /* Body Small */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13.33px;
  line-height: 16px;
  /* or 120% */

  font-feature-settings: "liga" off;

  color: #ffffff;
}

.footer-container .copyright-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 28px;
  padding: 8px 16px;
  gap: 16px;
}

.footer-container .copyright-container > span {
  text-align: start;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 13.33px;
  line-height: 16px;
  /* or 120% */

  font-feature-settings: "liga" off;

  color: #ffffff;
}

.footer-container .copyright-container .social-media-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 16px;
}

.footer-container .cvm-logo-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}
