@media screen and (max-width: 768px) {
  .container__faq {
    position: relative;
    text-align: center;
    overflow: hidden;
    max-height: 1500px;
  }

  .container__faq > img {
    width: 100%;
    margin-top: -10%;
  }

  .container__faq .title_container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    top: 64px;
  }

  .container__faq .title_container .title {
    font-size: 37.33px;
    line-height: 36px;
  }

  .container__faq .container-accordions {
    width: 540px;
  }

  .container__faq .container-accordions .accordion__closed {
    width: 540px;
  }

  .container__faq .container-accordions .accordion__open {
    cursor: pointer;
    width: 540px;
    max-height: 328px;
  }

  .container__faq .container-accordions .accordion__open .accordion__header {
    width: 540px;
  }
}

@media screen and (max-width: 425px) {
  .container__faq {
    position: relative;
    text-align: center;
    overflow: hidden;
    max-height: 1100px;
  }

  .container__faq > img {
    width: 100%;
    margin-top: -10%;
  }

  .container__faq .title_container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    top: 64px;
  }

  .container__faq .title_container .title {
    font-size: 37.33px;
    line-height: 36px;
  }

  .container__faq .container-accordions {
    width: 300px;
  }

  .container__faq .container-accordions .accordion__closed {
    width: 270px;
  }

  .container__faq .container-accordions .accordion__open {
    cursor: pointer;
    width: 270px;
    max-height: 328px;
  }

  .container__faq .container-accordions .accordion__open .accordion__header {
    width: 270px;
  }
}
